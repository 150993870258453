<template>
    <div>
        <CustomerDetails :name="name" :product_name="product_name" :full_amount="full_amount" :residence_literal="residence_literal" :destination_literal="destination_literal" :state="state" :email="email" :customers="customers" :end_date="end_date" :start_date ="start_date " :issueDate="issueDate" :customer_dts="customer_dts" :telephone="telephone" :address="address"/>
        <!-- customers table insurance evidence -->
        <b-row class="mb-3">
            <b-col cols="12">
                <b-table striped hover responsive
                :items="items"
                :fields="fields"
                :busy="isBusy">
                <template #table-busy>
                    <div class="d-flex align-items-center justify-content-around my-2">
                        <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                    </div>
                </template>
                <template #cell(ins_ev)="data">
                    <b-button size="sm" variant="outline-secondary" target="_blank" :href="policyLink+'&tr='+data.index">Download</b-button>
                </template>
                </b-table>
            </b-col>

            <b-col cols="12">
                <div v-if="this.policyLink">
                    <b-button size="sm" variant="success" target="_blank" :href="policyLink">Insurance Certificate</b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
  <script>
  import { token_id, products, customersDtsMixin } from '@/products';
  import CustomerDetails from './CustomerDetails';
  import { BRow, BCol, BJumbotron, BTable, BButton, BCard, BCardText, BCardTitle, BCardBody, BSpinner } from 'bootstrap-vue'
  
  
  export default {
    mixins: [customersDtsMixin],
    components: {
      CustomerDetails,
      BRow,
      BCol,
      BJumbotron,
      BTable,
      BButton,
      BCard,
      BCardText,
      BCardTitle,
      BCardBody,
      BSpinner
    },
    data() {
        return {
            isBusy: true,
            name: [],
            start_date: '',
            end_date: '',
            state: '',
            issueDate: '',
            email: '',
            product_name: '',
            full_amount: '',
            residence_literal: '',
            destination_literal: '',
            telephone: '',
            address: '',
            customers:[],
            items:[],
            fields: [
                { key: 'no', label: 'No.', sortable: true },
                { key: 'gender', label: 'Gender', sortable: true },
                { key: 'customer_name', label: 'Customer Name', sortable: true },
                { key: 'date_of_birth', label: 'Date of Birth', sortable: true },
                { key: 'age', label: 'Age', sortable: true },
                { key: 'ins_ev', label: 'Insurance Certificate', sortable: true },
            ],
            debitnoteLink:'',
            tr_token:'',
            customer_dts:'',
            on_behalf_of:'',
            t_tokenized:'',
            products, 
            policyLink:'',
            tr_token:'',
            on_behalf_of:'',
            token_id
        }
    },
    async created() {
        await this.urlParams();
        await this.getTripDts()
        await this.getCustomers();
        await this.getMember(this.on_behalf_of);
        await this.checkCertificate(this.tr_tokenized);
    },
  }
  </script>
  <style>
    #jumDts {
        padding: 2rem;
    }
  </style>